@font-face {
  font-family: "Oswald";
  font-style: normal;
  font-weight: 300;
  font-display: fallback;
  src: local("Oswald Light"), url(./fonts/Oswald-Light.ttf) format("truetype");
}
@font-face {
  font-family: "Oswald";
  font-style: normal;
  font-weight: 400;
  font-display: fallback;
  src: local("Oswald Regular"),
    url(./fonts/Oswald-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "Oswald";
  font-style: normal;
  font-weight: 600;
  font-display: fallback;
  src: local("Oswald SemiBold"),
    url(./fonts/Oswald-SemiBold.ttf) format("truetype");
}
@font-face {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 300;
  font-display: fallback;
  src: local("Raleway Light"), url(./fonts/Raleway-Light.ttf) format("truetype");
}
@font-face {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  font-display: fallback;
  src: local("Raleway Regular"),
    url(./fonts/Raleway-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "Raleway";
  font-style: normal;
  font-display: fallback;
  font-weight: 600;
  src: local("Raleway SemiBold"),
    url(./fonts/Raleway-SemiBold.ttf) format("truetype");
}

:root {
  --secondary-color: #b0c4de;
  --primary-color: rgba(34, 174, 195, 1);
  --primary-color-rgb: 34, 174, 195;
  --text-color: #345acd;
  --text-color-rgb: 52, 90, 205;
  --link-color: #7fffd4;
  --articBlue: rgb(43, 225, 243);
  --darkBlue: #345acd;
  --aquamarine: #7fffd4;
  --raleway: "Raleway", sans-serif;
  --oswald: "Oswald", sans-serif;
  --customEase: cubic-bezier(0, 0.75, 1, 0.67);
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  color: var(--text-color);
  font-family: var(--raleway);
  font-weight: 400;
  font-size: 15px;
  background: linear-gradient(180deg, var(--primary-color) 0%, var(--secondary-color) 100%)
    no-repeat;
  min-height: 100vh;
  /* background: var(--lightBlue);
  background: linear-gradient(180deg, var(--teal) 0%, #010000 100%) no-repeat; */
  /* background: rgb(0, 144, 255);
  background: linear-gradient(
      180deg,
      rgba(0, 144, 255, 1) 4%,
      rgba(0, 191, 255, 1) 40%,
      rgba(0, 243, 255, 1) 100%
    )
    no-repeat; */
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--oswald);
  font-weight: 400;
}
a {
  color: var(--link-color);
  /* transition: all 0.3s var(--customEase); */
}
a:hover {
  background-color: var(--link-color);
  color: var(--text-color);
  transition: all 0.3s var(--customEase);
}
a:active {
  background-color: var(--link-color);
}
ul {
  list-style: none;
}
nav {
  font-size: 1.5em;
}

@media (min-width: 700px) {
  body {
    font-size: 18px;
  }
}
