.nav {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 0;
  margin: auto;
  width: 100%;
}

.nav li {
  border-radius: 3px;
  height: 28px;
  width: 28px;
}

.nav li:hover {
  background-color: var(--text-color);
  transition: background-color 0.3s var(--customEase);
}

.footer {
  background-color: transparent;
  width: 100%;
  padding: 30px;
  position: absolute;
  bottom: 0;
}

.footerLink {
  color: var(--darkBlue);
}

a svg:hover path {
  fill: var(--link-color);
  transition: fill 0.3s var(--customEase);
}

.footerLink:hover,
.footerLink:active {
  background-color: transparent;
}

@media (min-width: 700px) {
  .nav {
    width: 400px;
    justify-content: space-between;
  }
}
