.app {
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow-x: hidden;
}

@media (max-width: 768px) {
  .app {
    padding-top: 3rem;
    justify-content: flex-start;
  }
}
