.main {
  width: 300px;
  position: relative;
  margin: 0 auto;
}

h1 {
  margin-top: 0;
  font-size: 2em;
}

p {
  font-size: 1.1em;
}

.profilePic {
  width: 80px;
  height: 80px;
  border-radius: 5px;
  opacity: 0.9;
  margin-right: 15px;
  cursor: pointer;
  transition: opacity 0.3s ease;
}

.profilePic:hover {
  opacity: 1;
}

.heading {
  display: flex;
  flex-direction: row;
  line-height: 1.1;
}

.heading h1 {
  margin: 0;
}

.name {
  display: flex;
  flex-direction: column;
  justify-content: end;
  cursor: pointer;
}

.bio {
  transition: height 200ms ease-in;
  overflow: hidden;
  margin-bottom: 1em;
}

.employment {
  height: 120%;
}

small {
  cursor: pointer;
  transition: background-color 0.3s var(--customEase);
}

small:hover {
  background-color: var(--text-color);
  color: var(--link-color);
}

.jobHeader {
  margin-bottom: 1rem;
  color: var(--text-color);
  opacity: 0.8;
}

.jobHeader h2 {
  margin: 0;
  font-size: 1.6em;
  color: var(--text-color);
}

.jobHeader h4 {
  margin: 0.2rem 0 0.4rem;
  font-size: 1em;
  font-weight: 400;
}

.employmentYears {
  margin-bottom: 0.8rem;
  font-size: 0.9rem;
  opacity: 0.7;
  font-style: italic;
}

.techStack {
  display: flex;
  flex-wrap: wrap;
  margin-top: 0.5rem;
}

.techItem {
  font-size: 0.8rem;
  padding: 0.2rem 0.6rem;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  background-color: rgba(var(--text-color-rgb), 0.1);
  border-radius: 20px;
  color: var(--text-color);
}

@media (min-width: 700px) {
  .main {
    width: 600px;
  }

  p {
    font-size: 1.2em;
  }
}

@media (max-width: 768px) {
  .main {
    margin: 0 auto;
    width: 90%;
    max-width: 500px;
  }
}
